<template>
  <div class="content">
    <div class="orgBox">
      <div class="orgName">
        <span
            style="display: inline-block;text-overflow:ellipsis;width: 16rem;overflow: hidden;white-space: nowrap"></span>
      </div>
    </div>
    <main v-loading="loading">
      <div class="left-content" v-if="false">
        <div class="top-eng">
          <span>Regional operation</span>
        </div>
        <div class="mid-word">区域运营管理平台</div>
        <div class="bottom-desc">
          构建区域运营一体化管理平台，重塑数字化管理
        </div>
      </div>
      <div class="rigth-content">
        <circle-menu v-if="menuShow" :linkMsg="toplink" />
        <UserSelectionDialog v-if="showDialog" :users="users" @select="handleUserSelect" />
      </div>
    </main>
  </div>
</template>

<script>
import top_fk from '@/assets/images/orgIndex/top_fk.png'
import top_base from '@/assets/images/orgIndex/base.png'
import top_auth from '@/assets/images/orgIndex/auth.png'
import top_money from '@/assets/images/orgIndex/money.png'
import UserSelectionDialog from './userSelectionDialog.vue';
import top_uap from '@/assets/images/orgIndex/top_uap.png'
import service from '@/api/login'
import circleMenu from '@/components/circleMenu/index'
export default {
  components: { circleMenu, UserSelectionDialog },
  data() {
    return {
      toplink: [
        { code: "Base-Archives", iconName: "base", name: "基础档案", canOpen: false},
        { code: "FK", iconName: "top_fk", name: "费控中台", canOpen: false},
        { code: "JSZX", iconName: "money", name: "财务结算", canOpen: false},
        { code: "NO", iconName: "base", name: "权限配置", canOpen: false},
        { code: "JZPZ", iconName: "top_uap", name: "UAP", canOpen: false},
        { code: "BIG", iconName: "drive", name: "公司驾驶舱", canOpen: false},
        { code: "BIP", iconName: "drive", name: "平台驾驶舱", canOpen: false},
      ],
      menuShow: false,
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > Date.now() - 8.64e7
      //   }
      // },
      loading: false,
      showDialog: false,
      users: []
    }
  },
  computed: {
    token() {
      return localStorage.getItem('clickToken')
    }
  },
  watch: {},
  created() {
    this.iamLogin()
  },
  mounted() {

    // this.getModules();
    //清空tag缓存
    // this.$store.commit('tagsView/SET_VISITED_VIEWS', []);
    // this.isEditPassWord();//判断是否需要重置密码
  },
  methods: {
    handleUserSelect(selectedUser) {
      // 处理用户选择的逻辑，selectedUser为用户选择的对象
      console.log('选中的用户ID:', selectedUser.id);
      this.showDialog = false; // 隐藏弹窗
      this.iamPushLogin(selectedUser.id)
    },
    //获取图标
    getModules() {
      service.getConsoleIcon().then(resp => {
        if (resp.success) {
          this.toplink.forEach(f => {
            f.iconUrl = require("@/assets/images/orgIndex/" + f.iconName + ".png")
          })
          let returnArr = resp.data.resourceVos || []
          for (let i in this.toplink) {
            for (let j in returnArr) {
              if (returnArr[j].code === this.toplink[i].code) {
                this.toplink[i].canOpen = true
              }
            }
          }
          this.menuShow = true
        }
      })
    },
    iamLogin(){
        console.log('1111111')
        const rex = /code=(.*?)&/
        const param = location.href.match(rex)
        // 判断是否有token 有的话则认为是其他模块返回的
        if((!param || Object.keys(param).length < 2)) {
          if(!(localStorage.getItem('token') && localStorage.getItem('token') !== '' && localStorage.getItem('token') !== undefined)){
            window.location.href = process.env.VUE_APP_IAM_LOGIN_API + '/login/?response_type=code&project=haier-rrs-login-page&client_id=' + process.env.VUE_APP_IAM_CLIENT_ID + '&redirect_uri=' + process.env.VUE_APP_IAM_RETURN_URL + '&sync-third-token=false&state=#login'
          }
          if(localStorage.getItem('token') && localStorage.getItem('token') !== '' && localStorage.getItem('token') !== undefined ){
            console.log('token',localStorage.getItem('token'))
            this.getModules()
            this.$store.commit('tagsView/SET_VISITED_VIEWS', []);
          }
        } else {
          if(localStorage.getItem('token') && localStorage.getItem('token') !== '' && localStorage.getItem('token') !== undefined ){
            console.log('token',localStorage.getItem('token'))
            this.getModules()
            this.$store.commit('tagsView/SET_VISITED_VIEWS', []);
          } else {
            // 判断是否是需要选择权限
            service.getRepeatUserInfo(param[1]).then(res => {
                  console.log('1111111', res)
                  if (res.code === 400) {
                    this.$message.error(res.message)
                  }
                  if (res.code === 200) {
                    // 多条的话需要选择权限
                    if (res.data.length === 1) {
                      this.iamPushLogin(res.data[0].id)
                    } else if (res.data.length > 1) {
                      console.log('2131231')
                      this.users = res.data
                      console.log('user', this.users)
                      this.showDialog = true
                    }
                  }
                }
            )
          }
        }
    },

    iamPushLogin(code){
      service.getIamToken(code).then(res => {
        if (res.code === 400) {
          this.$message.error(res.message)
        }
        if (res.code === 200) {
          localStorage.setItem('token', res.data.gmsToken);
          localStorage.setItem('expiresAt',Date.now() + 3600000);
          localStorage.setItem('clickToken', res.data.gmsToken);
          this.$store.commit('user/SET_TOKEN', +res.data.gmsToken)
          service.logInfo({token: res.data.gmsToken}).then(resp =>{
            this.$store.dispatch('user/set_user', resp.data)
            this.getModules()
            this.$store.commit('tagsView/SET_VISITED_VIEWS', []);
          })
        }
      }).catch(() => {
        this.pushLogin()
      })
    },
    pushLogin(){
      window.location.href = process.env.VUE_APP_IAM_LOGIN_API + '/login/?response_type=code&project=haier-rrs-login-page&client_id=' + process.env.VUE_APP_IAM_CLIENT_ID + '&redirect_uri=' + process.env.VUE_APP_IAM_RETURN_URL + '&sync-third-token=false&state=#login'
    },
    //校验是否重新设置密码
    isEditPassWord(){
      service.logInfo({token: localStorage.token}).then(resp => {
        if (resp.success) {
          //是否需要修改密码
          if(!this.$store.state.user.isEditPass){
            this.$confirm('您的密码强度太弱，请前往修改密码', '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              showClose: false,
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false,
            }).then(() => {
              this.$router.push({name: 'restPsw'})
            })
          }else if(resp.data.passWordFlag === 0){
            this.$confirm('您的密码已过有效期，请重新修改密码', '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              showClose: false,
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false,
            }).then(() => {
              this.$router.push({name: 'restPsw'})
            })
          }
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background: #F5F7FA;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  .orgBox {
    height: 0;
  }
  main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    background: url(../assets/images/consolebg.png) no-repeat center center;
    background-size: 100%;
    ba
    .left-content {
      div {
        margin-bottom: 20px;
        font-size: 20px;
      }
      .top-eng {
        color: #cc3300;
        padding-left: 15px;
        border-left: #cc3300 8px solid;
      }
      .mid-word {
        letter-spacing: 6px;
      }
      .bottom-desc {
        font-size: 16px;
        color: #999;
      }
    }
    .rigth-content {
    }
  }
}

.svg-icon {
  margin-left: 10px;
}

</style>
