<template>
  <div class="user-selection-dialog">
    <h2>请选择要登录的账号</h2>
    <div class="image-container">
      <img :src="imgSrc" alt="Logo" class="logo-image" />
    </div>
    <div class="table-container">
      <table class="user-table">
        <colgroup>
          <col style="width: 20%;" />
          <col style="width: 40%;" />
          <col style="width: 40%;" />
        </colgroup>
        <thead>
        <tr>
          <th></th>
          <th>手机号码</th>
          <th>海尔工号</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in users" :key="user.id" @click="selectUser(index)" :class="{ 'selected': index === selectedIndex }">
          <td><input type="radio" v-model="selectedIndex" :value="index" /></td>
          <td>{{ user.phone }}</td>
          <td>{{ user.haierCode }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="button-container">
      <button :disabled="selectedIndex === -1" @click="confirmSelection">确定</button>
    </div>
  </div>
</template>

<script>
import img from '@/assets/images/logoinC.png'
export default {
  name: 'UserSelectionDialog',
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedIndex: -1,
      imgSrc: img
    };
  },
  methods: {
    selectUser(index) {
      this.selectedIndex = index;
    },
    confirmSelection() {
      const selectedUser = this.users[this.selectedIndex];
      this.$emit('select', selectedUser);
    }
  }
}
</script>

<style scoped>
.user-selection-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 400px; /* 设置外部容器的宽度 */
}

h2 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto; /* 添加水平滚动条 */
  max-width: 100%; /* 表格最大宽度为100% */
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 8px;
  table-layout: fixed;
}
.image-container {
  position: absolute;
  top: 5px; /* 调整图片位置 */
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-image {
  width: 50px; /* 根据需要调整图片宽度 */
  height: auto;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center; /* 设置文本居中 */
}

th {
  background-color: #f5f5f5;
}

tr.selected {
  background-color: #f0f8ff;
}

.button-container {
  display: flex;
  justify-content: center;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
